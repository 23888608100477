import { Color, Mesh, MeshBasicMaterial, Object3D } from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';

const defaultAnimatedTextProps = {
  color: new Color(0xffffff),
  size: 1,
};

export type AnimatedTextProps = Partial<typeof defaultAnimatedTextProps>;

export class AnimatedText extends Object3D {
  private font: Font | null = null;
  public readonly text: string;
  private readonly props: Required<AnimatedTextProps>;

  private _geometry: TextGeometry | null = null;
  private _material: MeshBasicMaterial | null = null;
  private _mesh: Mesh | null = null;

  public get geometry() {
    return this._geometry;
  }

  public get material() {
    return this._material;
  }

  public get mesh() {
    return this._mesh;
  }

  constructor(text: string, props?: AnimatedTextProps) {
    const mergedProps = {
      ...defaultAnimatedTextProps,
      ...props,
    };

    super();

    this.text = text;
    this.props = mergedProps;
  }

  public async init() {
    const font = await this.loadFont();

    const geometry = new TextGeometry(this.text, {
      font,
      size: this.props.size,
      height: 0,
    });

    geometry.center();

    const material = new MeshBasicMaterial({
      color: this.props.color,
      transparent: true,
      depthTest: false,
    });
    const mesh = new Mesh(geometry, material);

    mesh.renderOrder = 1;

    this.add(mesh);

    this._geometry = geometry;
    this._material = material;
    this._mesh = mesh;

    return this;
  }

  private async loadFont() {
    const fontLoader = new FontLoader();
    this.font = await fontLoader.loadAsync('assets/neuePlak.webgl.json');

    return this.font;
  }
}
