import { MeshLineGeometry, MeshLineMaterial } from '@lume/three-meshline';
import { Color, Mesh, Vector3 } from 'three';

const defaultAnimatedMeshLineProps = {
  /**
   * Width of the line
   */
  width: 0.1,
  /**
   * Speed of the dash offset animation
   */
  speed: 0.01,
  /**
   * Visible length of the line. The visible length is the length of the line that is not dashed.
   */
  visibleLength: 1,
  /**
   * Color of the line material
   */
  color: new Color(0xffffff),
  /**
   * Opacity of the line material
   */
  opacity: 1,
  /**
   * Position of the line
   */
  position: new Vector3(0, 0, 0),
  /**
   * Function to transform the line with a custom transformation function
   */
  transformLine: (line: number) => line,
  /**
   * Points of the line
   */
  points: [new Vector3(-1, 0, 0), new Vector3(0, 1, 0), new Vector3(1, 0, 0)],
};

export type AnimatedMeshLineProps = Partial<
  typeof defaultAnimatedMeshLineProps
>;

/**
 * Class to generate a line with a dash offset animation
 */
export class AnimatedMeshLine extends Mesh<MeshLineGeometry, MeshLineMaterial> {
  private readonly voidLength: number;
  private dyingAt = 1;

  private readonly props: typeof defaultAnimatedMeshLineProps & {
    points: Array<Vector3>;
  };

  private get diedAt() {
    return this.dyingAt + this.voidLength;
  }

  constructor(props: AnimatedMeshLineProps) {
    const mergedProps = {
      ...defaultAnimatedMeshLineProps,
      ...props,
    };

    const {
      color,
      width,
      opacity,
      transformLine,
      position,
      visibleLength,
      points,
    } = mergedProps;

    const dashArray = 2;
    const dashOffset = 0;
    const dashRatio = 1 - visibleLength / 2;

    // @ts-expect-error MeshLineMaterial constructor type is not correctly defined in @lume/three-meshline
    const material = new MeshLineMaterial({
      color,
      lineWidth: width,
      dashRatio,
      dashArray,
      dashOffset,
      transparent: true,
      depthWrite: false,
      opacity,
    });

    const geometry = new MeshLineGeometry();
    geometry.setPoints(points, transformLine);

    super(geometry, material);

    this.position.copy(position);
    this.voidLength = dashArray * dashRatio;

    this.props = mergedProps;
  }

  /**
   * Update the dash offset. If the line is dying, also update the opacity. Ideally this method should be called in the render loop.
   */
  public update() {
    this.material.uniforms.dashOffset.value -= this.props.speed;

    if (this.isDying) {
      this.material.uniforms.opacity.value -= this.props.opacity / 100;
    }
  }

  /**
   * Check if the line is dead. A line is dead when the dash offset is less than the negative of the sum of the dash array and the dash ratio.
   */
  public get isDead() {
    return this.material.uniforms.dashOffset.value < -this.diedAt;
  }

  /**
   * Check if the line is dying. A line is dying when the dash offset is less than the negative of the sum of the dash array and the dash ratio.
   */
  public get isDying() {
    return this.material.uniforms.dashOffset.value < -this.dyingAt;
  }
}
