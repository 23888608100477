import { debounce } from 'lodash';
import { MutableRefObject, useEffect, useState } from 'react';
import { ColorRepresentation } from 'three';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { SwirlLineGenerator } from '../webgl/SwirlLineGenerator';
import { WebglController } from '../webgl/WebglController';

export function useSwirlGenerator({
  canvasRef,
  wrapperRef,
}: {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
}) {
  const controller = useWebglController({
    canvasRef,
    wrapperRef,
    initialColor: '#13ca91',
  });

  useEffect(() => {
    if (!controller) return;
    controller.camera.position.z = 6;

    const swirlGenerator = new SwirlLineGenerator({
      frequency: 0.7,
      transformLine: (line) => line,
      controller,
    });

    controller?.scene.add(swirlGenerator);
    swirlGenerator.start();

    let animationFrameId: number;

    function tick() {
      if (controller) {
        animationFrameId = requestAnimationFrame(tick);

        swirlGenerator.update();
        controller.renderer.render(controller.scene, controller.camera);

        // controller.camera.lookAt(swirlGenerator.position);
      }
    }

    tick();

    return () => {
      cancelAnimationFrame(animationFrameId);
      controller?.dispose();
    };
  }, [controller]);

  return controller;
}

function useWebglController({
  canvasRef,
  wrapperRef,
  initialColor,
}: {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  initialColor?: ColorRepresentation;
}) {
  const isReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

  const [glController, setGlController] = useState<WebglController | null>(
    null
  );

  useEffect(() => {
    let controller: WebglController | null = null;

    if (canvasRef.current && wrapperRef.current && !isReducedMotion) {
      controller = new WebglController({
        canvas: canvasRef.current,
        wrapper: wrapperRef.current,
        initialColor,
      });
      setGlController(controller);
    }

    return () => {
      controller?.dispose();
      setGlController(null);
    };
  }, [canvasRef, initialColor, isReducedMotion, wrapperRef]);

  useEffect(() => {
    const onResize = debounce(
      () => {
        glController?.resize();
      },
      50,
      {
        leading: true,
        trailing: true,
      }
    );

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [glController]);

  return glController;
}
