import { gsap } from 'gsap';
import { env } from 'helpers/env';
import * as lilGui from 'lil-gui';
import {
  Color,
  ColorRepresentation,
  LinearSRGBColorSpace,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from 'three';

import { PubSub } from './PubSub';

export class WebglController {
  /**
   * The scene used by the WebGLRenderer.
   */
  public readonly scene = new Scene();

  /**
   * The WebGLRenderer used to render the scene.
   */
  public readonly renderer: WebGLRenderer;

  /**
   * The camera used by the WebGLRenderer.
   */
  public readonly camera: PerspectiveCamera;

  public readonly debugGui =
    env.NODE_ENV === 'development' ? new lilGui.GUI({}) : null;

  private _isDisposed = false;
  /**
   * Whether the WebGLRenderer has been disposed or not yet.
   */
  public get isDisposed(): boolean {
    return this._isDisposed;
  }

  private readonly pubSub = new PubSub<{
    colorChange: Color;
  }>();
  public readonly subscribe = this.pubSub.subscribe;
  public readonly unsubscribe = this.pubSub.unsubscribe;

  constructor(
    public readonly props: {
      canvas: HTMLCanvasElement;
      wrapper: HTMLDivElement;
      initialColor?: ColorRepresentation;
    }
  ) {
    const wrapperDimensions = this.props.wrapper.getBoundingClientRect();
    this.camera = new PerspectiveCamera(
      75,
      wrapperDimensions.width / wrapperDimensions.height,
      0.1,
      1000
    );

    this.renderer = new WebGLRenderer({
      canvas: this.props.canvas,
      antialias: true,
    });
    this.renderer.outputColorSpace = LinearSRGBColorSpace;
    this.renderer.setSize(wrapperDimensions.width, wrapperDimensions.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3));

    if (this.props.initialColor) {
      this.renderer.setClearColor(
        new Color(this.props.initialColor).convertLinearToSRGB()
      );
    }
  }

  /**
   * Resize the canvas and the camera aspect ratio. Should be called when the window is resized. Make sure to debounce it.
   */
  public resize() {
    const wrapperDimensions = this.props.wrapper.getBoundingClientRect();
    this.camera.aspect = wrapperDimensions.width / wrapperDimensions.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(wrapperDimensions.width, wrapperDimensions.height);
  }

  /**
   * Dispose the WebGLRenderer and set the isDisposed flag to true. Should be called when the component is unmounted or when the WebGLRenderer is not needed anymore.
   */
  public dispose() {
    this.debugGui?.destroy();

    this._isDisposed = true;
  }

  public animateColor(color: ColorRepresentation) {
    const currentColor = new Color(this.renderer.getClearColor(new Color()));
    const colorTarget = new Color(color).convertLinearToSRGB();

    gsap.to(currentColor, {
      duration: 1.2,
      ...colorTarget,
      onUpdate: () => {
        this.renderer.setClearColor(currentColor);
        this.pubSub.publish('colorChange', currentColor);
      },
    });
  }
}
