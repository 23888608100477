import(/* webpackMode: "eager" */ "/vercel/path0/components/commonComponents/heading/heading.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Paragraph"] */ "/vercel/path0/components/commonComponents/paragraph/paragraph.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Stats"] */ "/vercel/path0/components/commonComponents/stats/stats.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/commonComponents/testimonial/testimonial.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactFormSection"] */ "/vercel/path0/components/contact-form-section/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactInfoBlock"] */ "/vercel/path0/components/contact-info-block/contactInfoBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Head"] */ "/vercel/path0/components/internalComponents/head/head.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pageComponents/container/container.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["HomeHero"] */ "/vercel/path0/components/pageComponents/hero/homeHero.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pageComponents/section/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["BlogPosts"] */ "/vercel/path0/components/selfContainedComponents/blogPosts/blogPosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PeopleCarousel"] */ "/vercel/path0/components/selfContainedComponents/carousel/peopleCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PicturesCarousel"] */ "/vercel/path0/components/selfContainedComponents/carousel/picturesCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestimonialCarousel"] */ "/vercel/path0/components/selfContainedComponents/carousel/testimonialCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cases"] */ "/vercel/path0/components/selfContainedComponents/cases/cases.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Clients"] */ "/vercel/path0/components/selfContainedComponents/clients/clients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactCapi"] */ "/vercel/path0/components/selfContainedComponents/contactCapi/contactCapi.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IllustrationRow"] */ "/vercel/path0/components/selfContainedComponents/illustrationRow/illustrationRow.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/mediaEmbed/mediaEmbed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShowCase"] */ "/vercel/path0/components/selfContainedComponents/showCase/showCase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleList"] */ "/vercel/path0/components/selfContainedComponents/simpleList/simpleList.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/testimonialGrid/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/textBlock/textBlock.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/tileList/tileList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Vacancies"] */ "/vercel/path0/components/selfContainedComponents/vacancies/vacancies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VacancyList"] */ "/vercel/path0/components/selfContainedComponents/vacancyList/vacancyList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseCarousel"] */ "/vercel/path0/components/tileCarousel/caseCarousel/caseCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsCarousel"] */ "/vercel/path0/components/tileCarousel/newsCarousel/newsCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeamCarousel"] */ "/vercel/path0/components/tileCarousel/teamCarousel/teamCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VacancyForm"] */ "/vercel/path0/components/vacancy-form/index.tsx");
