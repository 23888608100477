import { HTMLAttributes, useEffect, useRef } from 'react';
import { ColorRepresentation } from 'three';

import { useAnimatedText } from './hooks/useAnimatedText';
import { useMousePositionAnimation } from './hooks/useMousePositionAnimation';
import { useSwirlGenerator } from './hooks/useSwirlGenerator';
import styles from './webglSwirlLine.module.scss';

export function WebglSwirlLine({
  color = 0x13ca91,
  topTexts = [],
  bottomText,
  activeTopTextIndex = 0,
  textAs: Text = 'h2',
  className,
  ...props
}: {
  color?: ColorRepresentation;
  topTexts?: ReadonlyArray<string>;
  bottomText?: string;
  activeTopTextIndex?: number;
  textAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'color'>) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const controller = useSwirlGenerator({ canvasRef, wrapperRef });

  useMousePositionAnimation(controller);

  useEffect(() => {
    if (!controller) return;
    controller.animateColor(color);
  }, [color, controller]);

  useAnimatedText({
    bottomText,
    topTexts,
    controller,
    activeTopTextIndex,
  });

  return (
    <div
      ref={wrapperRef}
      className={[className, styles.webglSwirlLine].join(' ')}
      {...props}
    >
      <Text className={styles.text}>
        {topTexts[activeTopTextIndex]}
        <br />
        {bottomText}
      </Text>
      <canvas
        className={styles.canvas}
        ref={canvasRef}
        style={{
          visibility: controller ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
}
