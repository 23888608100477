import { gsap } from 'gsap';
import { useEffect, useMemo, useState } from 'react';
import { Box3, Plane, Vector3 } from 'three';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { AnimatedText } from '../webgl/AnimatedText';
import { WebglController } from '../webgl/WebglController';

export function useAnimatedText({
  bottomText,
  topTexts = [],
  controller,
  activeTopTextIndex,
}: {
  bottomText: string | undefined;
  topTexts: ReadonlyArray<string> | undefined;
  controller: WebglController | null;
  activeTopTextIndex: number | undefined;
}) {
  const [topTextObjects, setTopTextObjects] = useState<AnimatedText[]>([]);

  const textSize = useTextSize();

  useEffect(() => {
    const loadedTexts: Array<AnimatedText> = [];

    if (bottomText) {
      Promise.all([
        Promise.all(
          topTexts.map((text) =>
            new AnimatedText(text, {
              size: textSize,
            }).init()
          )
        ),
        new AnimatedText(bottomText, {
          size: textSize,
        }).init(),
      ]).then(([tops, bottom]) => {
        if (!controller) return;
        loadedTexts.push(...tops, bottom);

        const size = new Vector3();

        const boxSize = new Vector3(0, textSize + 0.1 * textSize, 0);
        const virtualBox = new Box3(new Vector3(), boxSize);
        const topClipPlane = new Plane(new Vector3(0, -1, 0), virtualBox.max.y);
        const bottomClipPlane = new Plane(
          new Vector3(0, 1, 0),
          virtualBox.min.y
        );
        controller.renderer.localClippingEnabled = true;

        tops.forEach((top, index) => {
          top.position.z = 2;
          top.geometry?.computeBoundingBox();
          top.geometry?.boundingBox?.getSize(size);

          const ySize = size.y + 0.1 * textSize;

          const newY = index * ySize;
          top.position.y = newY + ySize / 2;

          controller.scene.add(top);

          if (top.material)
            top.material.clippingPlanes = [topClipPlane, bottomClipPlane];
        });

        setTopTextObjects(tops);

        bottom.position.z = 2;
        bottom.geometry?.computeBoundingBox();
        bottom.geometry?.boundingBox?.getSize(size);
        bottom.position.y = -size.y / 2 - (0.1 * textSize) / 2;
        controller?.scene.add(bottom);
      });
    }

    return () => {
      controller?.scene.remove(...loadedTexts);
      setTopTextObjects([]);
    };
  }, [bottomText, controller, textSize, topTexts]);

  useTextPositionAnimation({
    controller,
    topTextObjects,
    textSize,
    activeTopTextIndex,
  });
}
function useTextSize() {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return useMemo(() => {
    if (isDesktop) {
      return 1.4;
    }
    return 0.8;
  }, [isDesktop]);
}

function useTextPositionAnimation({
  controller,
  topTextObjects,
  textSize,
  activeTopTextIndex = 0,
}: {
  controller: WebglController | null;
  topTextObjects: ReadonlyArray<AnimatedText>;
  textSize: number;
  activeTopTextIndex: number | undefined;
}) {
  useEffect(() => {
    if (!controller) return;

    topTextObjects.forEach((text, index) => {
      const size = new Vector3();
      text.geometry?.computeBoundingBox();
      text.geometry?.boundingBox?.getSize(size);

      const ySize = size.y + textSize * 0.1;

      const newY = activeTopTextIndex * -ySize + index * ySize;

      gsap.to(text.position, {
        y: newY + ySize / 2,
        duration: 1.2,
        ease: 'expo.inOut',
      });
    });
  }, [activeTopTextIndex, controller, textSize, topTextObjects]);
}
