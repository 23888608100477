import {
  clamp,
  lerp,
} from '@/components/commonComponents/webglSwirlLine/webglSwirlLine.utils';
import { useEffect, useRef } from 'react';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { WebglController } from '../webgl/WebglController';

function useMousePosition() {
  const mousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    function onMouseMove(event: MouseEvent) {
      mousePosition.current.x = event.clientX;
      mousePosition.current.y = event.clientY;
    }

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return mousePosition;
}

export function useMousePositionAnimation(controller: WebglController | null) {
  const mousePosition = useMousePosition();

  const isTouchDevice = useMediaQuery('(pointer: coarse)');
  const scrollY = useRef(0);

  useEffect(() => {
    function onScroll() {
      scrollY.current = window.scrollY;
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (!controller) return;

    let animationFrameId: number;

    function tick() {
      animationFrameId = requestAnimationFrame(tick);

      if (controller) {
        let newX = 0;
        let newY;

        if (isTouchDevice) {
          newY = clamp(
            ((scrollY.current * 2) / window.innerWidth - 0.5) * 2.4,
            -1.2,
            1.2
          );
          newX = 0;

          controller.camera.lookAt(0, 0, 2);
        } else {
          newX = (mousePosition.current.x / window.innerWidth - 0.5) * -2.8;
          newY = (mousePosition.current.y / window.innerHeight - 0.5) * 2.8;

          controller.camera.lookAt(0, 0, 0);
        }

        controller.camera.position.x = lerp(
          controller.camera.position.x,
          newX,
          0.01
        );
        controller.camera.position.y = lerp(
          controller.camera.position.y,
          newY,
          0.01
        );
      }
    }

    tick();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [controller, isTouchDevice, mousePosition]);
}
