export class PubSub<Events extends Record<string, unknown>> {
  private readonly subscribers = new Map<
    keyof Events,
    Set<(payload: Events[keyof Events]) => void>
  >();

  public subscribe = <EventName extends keyof Events>(
    eventName: EventName,
    callback: (payload: Events[EventName]) => void
  ) => {
    if (!this.subscribers.has(eventName)) {
      this.subscribers.set(eventName, new Set());
    }

    this.subscribers
      .get(eventName)
      ?.add(callback as (payload: Events[keyof Events]) => void);
  };

  public unsubscribe = <EventName extends keyof Events>(
    eventName: EventName,
    callback: (payload: Events[EventName]) => void
  ) => {
    this.subscribers
      .get(eventName)
      ?.delete(callback as (payload: Events[keyof Events]) => void);

    if (this.subscribers.get(eventName)?.size === 0) {
      this.subscribers.delete(eventName);
    }
  };

  public publish = <EventName extends keyof Events>(
    eventName: EventName,
    payload: Events[EventName]
  ) => {
    this.subscribers.get(eventName)?.forEach((callback) => callback(payload));
  };
}
