import { clamp } from '@/components/commonComponents/webglSwirlLine/webglSwirlLine.utils';
import { random } from 'lodash';
import { Color, Object3D, Vector3 } from 'three';

import { AnimatedMeshLine } from './AnimatedMeshLine';
import { WebglController } from './WebglController';

const defaultLineGeneratorProps = {
  radiusStart: 0.3,
  radiusStartMin: 0.1,
  zMin: -1,
  zIncrement: 0.01,
  angleIncrement: 0.01,
  radiusIncrement: 0.008,
  speed: {
    min: 0.00008,
    max: 0.0025,
  },
  visibleLength: {
    min: 0.05,
    max: 0.4,
  },
  width: {
    min: 0.01,
    max: 0.2,
  },
  frequency: 0.1,
};

export type LineGeneratorProps = {
  transformLine?: (line: number) => number;
  controller: WebglController;
} & Partial<typeof defaultLineGeneratorProps>;

export class SwirlLineGenerator extends Object3D {
  private readonly controller: WebglController;

  private readonly props: typeof defaultLineGeneratorProps &
    Omit<LineGeneratorProps, 'controller'>;

  private currentColor = new Color();

  private isStarted = false;

  private lines: Array<AnimatedMeshLine> = [];

  constructor({ controller, ...props }: LineGeneratorProps) {
    super();

    this.controller = controller;

    this.props = {
      ...props,
      ...defaultLineGeneratorProps,
    };

    this.currentColor = new Color(
      this.controller.props.initialColor
    ).convertLinearToSRGB();

    this.controller.subscribe('colorChange', this.onColorChange);

    if (this.controller.debugGui) {
      this.setupDebug();
    }
  }

  /**
   * Start the line generation
   */
  public start() {
    this.isStarted = true;
  }

  /**
   * Stop the line generation
   */
  public stop() {
    this.isStarted = false;
  }

  /**
   * Add a line to the scene and the list of lines currently generated
   */
  public addLine(): AnimatedMeshLine | undefined {
    if (this.lines.length > 400) return;

    let z = this.props.zMin;
    let radius =
      Math.random() > 0.8 ? this.props.radiusStartMin : this.props.radiusStart;
    let angle = random(0, Math.PI * 2, true);
    const points: Array<Vector3> = [];
    while (z < this.controller.camera.position.z) {
      z += this.props.zIncrement;
      angle += this.props.angleIncrement;
      radius += this.props.radiusIncrement;

      points.push(
        new Vector3(radius * Math.cos(angle), radius * Math.sin(angle), z)
      );
    }

    const hsl = {
      h: 0,
      s: 0,
      l: 0,
    };
    this.currentColor.getHSL(hsl);

    const line = new AnimatedMeshLine({
      visibleLength: random(
        this.props.visibleLength.min,
        this.props.visibleLength.max
      ),
      points,
      speed: random(this.props.speed.min, this.props.speed.max),
      color: new Color().setHSL(
        hsl.h,
        clamp(hsl.s, 0.1, 1),
        clamp(hsl.l + random(-0.1, 0.4), 0.4, 0.8)
      ),
      width: random(this.props.width.min, this.props.width.max),
    });

    this.lines.push(line);
    this.add(line);

    return line;
  }

  /**
   * Remove a line from the scene and the list of lines currently generated
   * @param line
   */
  private removeLine(line: AnimatedMeshLine) {
    this.remove(line);
    this.lines = this.lines.filter((item) => item !== line);
  }

  /**
   * Remove all lines from the scene and the list of lines currently generated
   * @protected
   */
  private removeAllLines() {
    this.lines.forEach((line) => {
      this.remove(line);
    });
    this.lines = [];
  }

  /**
   * Update the lines and generate new ones if needed based on the frequency. Also remove the dead lines. Run this method in the render loop.
   */
  public update() {
    if (this.isStarted && Math.random() < this.props.frequency) {
      this.addLine();
    }

    for (const line of this.lines) {
      line.update();
    }

    this.lines = this.lines.filter((line) => {
      if (line.isDead) {
        this.removeLine(line);
        return false;
      }

      return line;
    });
  }

  private setupDebug() {
    if (!this.controller.debugGui) return;

    const folder = this.controller.debugGui.addFolder('SwirlLineGenerator');
    const speedFolder = folder.addFolder('speed');
    const widthFolder = folder.addFolder('width');
    const visibleLengthFolder = folder.addFolder('visibleLength');

    folder.add(this.props, 'radiusStart', 0.001, 1, 0.001);
    folder.add(this.props, 'radiusStartMin', 0.001, 1, 0.001);
    folder.add(this.props, 'zMin', -2, 4, 0.01);
    folder.add(this.props, 'zIncrement', 0.001, 0.1, 0.001);
    folder.add(this.props, 'angleIncrement', 0.001, 0.1, 0.001);
    folder.add(this.props, 'radiusIncrement', 0.001, 0.1, 0.001);

    speedFolder.add(this.props.speed, 'min', 0.00001, 0.001, 0.00001);
    speedFolder.add(this.props.speed, 'max', 0.00001, 0.01, 0.00001);

    widthFolder.add(this.props.width, 'min', 0.01, 1, 0.001);
    widthFolder.add(this.props.width, 'max', 0.01, 1, 0.001);

    visibleLengthFolder.add(this.props.visibleLength, 'min', 0.01, 1, 0.001);
    visibleLengthFolder.add(this.props.visibleLength, 'max', 0.01, 1, 0.001);

    folder.onChange(() => this.removeAllLines());
  }

  private onColorChange = (color: Color) => {
    this.currentColor = color;

    this.lines.forEach((line) => {
      const currentColor = new Color(line.material.color);

      const currentHsl = {
        h: 0,
        s: 0,
        l: 0,
      };
      currentColor.getHSL(currentHsl);

      const targetHsl = {
        h: 0,
        s: 0,
        l: 0,
      };
      color.getHSL(targetHsl);

      line.material.color.setHSL(
        targetHsl.h,
        clamp(targetHsl.s, 0.1, 1),
        clamp(currentHsl.l, 0.1, 0.8)
      );
    });
  };
}
