/**
 * Linear interpolation between two numbers.
 * @param a - start value
 * @param b - end value
 * @param t - interpolation factor between 0 and 1
 */
export function lerp(a: number, b: number, t: number) {
  return a + t * (b - a);
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
